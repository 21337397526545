import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import marked from "marked"

class BlogTemplate extends React.Component {
  render() {
    const { post } = this.props.data
    console.log("BlogTemplate -> render -> post", post)
    const { previous, next } = this.props.pageContext

    console.log(previous, next)
    return (
      <Layout>
        <SEO title={post.title} description={post.excerpt} />
        <div className="page-headline">
          <div className="container">
            <div className="section-heading text-center row">
              <div className="col-md-9 m-auto">
                {/* <h6 className="font-weight-bold text-uppercase flair">Blog</h6> */}
                <h1 className="mb-5">
                  <strong>{post.title}</strong>
                  <br />
                </h1>
                <em className="mx-2 d-lg-inline d-md-inline d-block">
                  <i className="fa fa-user pr-1" />
                  by: {post?.authors[0]?.person?.name}
                </em>
                <em className="mx-2 d-lg-inline d-md-inline d-block">
                  <i className="fa fa-calendar-o pr-1" />
                  {post.publishedAt}
                </em>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <Container>
            <div className="row justify-content-center">
              <div className="col-md-9">
                <img
                  alt=""
                  className="w-100 mb-5"
                  src={post.mainImage.asset.fluid.src}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(post.body || ""),
                  }}
                />
              </div>
            </div>
            <div className="blog-nav">
              <div className="row">
                <div className="col-md-6 text-md-left">
                  {previous ? (
                    <div>
                      <span className="font-weight-bold text-uppercase text-muted d-block small">
                        Previous
                      </span>
                      <Link to={`/${previous.slug.current}`}>
                        {previous.title}
                      </Link>
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 text-md-right">
                  {next ? (
                    <div>
                      <span className="font-weight-bold text-uppercase text-muted d-block small">
                        next
                      </span>
                      <Link to={`/${next.slug.current}`}>{next.title}</Link>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default BlogTemplate

export const blogQuery = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
      publishedAt(formatString: "MMMM DD, YYYY")
      authors {
        person {
          name
        }
      }
      body
      title
    }
  }
`
